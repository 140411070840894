import { BotsSDK } from "../BotsSDK";
import { DeviceInfoModel } from "../models/device-info.model";
import { WidgetConfigModel } from "../models/widget-config.model";

export default class UI {

    deviceInfo: DeviceInfoModel;
    config: WidgetConfigModel;
    widgetIframe: HTMLIFrameElement;
    widget: Document;

    constructor(private sdk: BotsSDK) {
        this.sdk.getDeviceInfo().then((info: DeviceInfoModel) => this.deviceInfo = info);
    }

    public setConfig(config: WidgetConfigModel) {
        this.config = config;
    }

    public setWidgetIframe(iframe: HTMLIFrameElement) {
        this.widgetIframe = iframe;
        this.widgetIframe.classList.toggle('mobile', this.isMobile())
    }

    public setWidget(widget: Document) {
        this.widget = widget;
    }

    public isMobile() {
        return this.deviceInfo.platform.type === "mobile";
    }

    public setPosition = (fullscreen?: boolean) => {
        this.addStyle(this.widgetIframe, 'z-index', '9999');
        this.addStyle(this.widgetIframe, 'position', 'fixed');

        const center = () => {
            this.addStyle(this.widgetIframe, 'top', fullscreen ? "0" : "50%");
            this.addStyle(this.widgetIframe, 'left', fullscreen ? "0" : "50%");
            this.addStyle(this.widgetIframe, 'transform', fullscreen ? "translate(0%, 0%)" : "translate(-50%, -50%)");
        }
        const right = () => this.addStyle(this.widgetIframe, 'right', fullscreen ? "0" : "16px");
        const left = () => this.addStyle(this.widgetIframe, 'left', fullscreen ? "0" : "16px");
        const top = () => this.addStyle(this.widgetIframe, 'top', fullscreen ? "0" : "16px");
        const bottom = () => this.addStyle(this.widgetIframe, 'bottom', fullscreen ? "0" : (this.config.enable_launcher ? "96px" : "16px"));

        switch (this.config[this.isMobile() ? "m_position" : "position"]) {
            case "br": bottom(); right(); break;
            case "bl": bottom(); left(); break;
            case "tr": top(); right(); break;
            case "tl": top(); left(); break;
            case "c": center(); break;
            case "custom": this.setCustomPosition(fullscreen); break;
        }
    };

    public setCustomPosition = (fullscreen?: boolean) => {
        this.addStyle(this.widgetIframe, 'inset', `
            ${this.config[this.isMobile() ? "m_top" : "top"] ? (fullscreen ? "0" : this.config[this.isMobile() ? "m_top" : "top"]) : "auto"}
            ${this.config[this.isMobile() ? "m_right" : "right"] ? (fullscreen ? "0" : this.config[this.isMobile() ? "m_right" : "right"]) : "auto"}
            ${this.config[this.isMobile() ? "m_bottom" : "bottom"] ? (fullscreen ? "0" : this.config[this.isMobile() ? "m_bottom" : "bottom"]) : "auto"}
            ${this.config[this.isMobile() ? "m_left" : "left"] ? (fullscreen ? "0" : this.config[this.isMobile() ? "m_left" : "left"]) : "auto"}
        `)
    };

    public setDimentions = () => {
        if (this.config[this.isMobile() ? "m_fullscreen" : "fullscreen"]) {
            this.setFullscreen();
        } else {
            this.setRegular();
        }
    };

    public setRegular = () => {
        this.widgetIframe.classList.toggle("fullscreen", false);
        this.setPosition();
        this.addStyle(this.widgetIframe, "width", this.isMobile() ? this.config.m_width : this.config.width);
        this.addStyle(this.widgetIframe, "height", this.isMobile() ? this.config.m_height : this.config.height);
        this.addStyle(this.widgetIframe, "border-radius", "12px");
        if (this.config.widget_shadow) {
            this.addStyle(this.widgetIframe, "box-shadow", `0 3px 6px ${this.config.border_color}`);
        }
        this.addStyle(this.widgetIframe, "border", `1px solid ${this.config.border_color}`);
    }

    public setFullscreen = () => {
        this.widgetIframe.classList.toggle("fullscreen", true);
        this.setPosition(true);
        this.addStyle(this.widgetIframe, "width", "100%");
        this.addStyle(this.widgetIframe, "height", "100%");
        this.addStyle(this.widgetIframe, "border-radius", "0");
        this.addStyle(this.widgetIframe, "box-shadow", "unset");
        this.addStyle(this.widgetIframe, "border", "none");
    }

    public toggleFullscreen = () => {
        if (this.widgetIframe.classList.contains("fullscreen")) {
            this.setRegular();
        } else {
            this.setFullscreen();
        }
    }

    public zoom = () => {
        if (!this.isMobile() && !this.config[this.isMobile() ? "m_fullscreen" : "fullscreen"]) {
            this.forceFullscreenIfOutOfBound();
            window.addEventListener('resize', this.forceFullscreenIfOutOfBound);
        }
        this.optimizeForTinyScreen();
        window.addEventListener('resize', this.optimizeForTinyScreen);
    }

    public forceFullscreenIfOutOfBound = () => {
        this.setRegular();
        this.widget.body.classList.remove("forcedFullscreen");
        setTimeout(() => {
            const isAlreadyFullScreen = this.widgetIframe.classList.contains("fullscreen")
            const outOfBound = this.isOutOfBound(this.widgetIframe.getBoundingClientRect());
            if (outOfBound && !isAlreadyFullScreen) {
                this.setFullscreen();
                this.widget.body.classList.add("forcedFullscreen");
            }
        }, 10)
    }

    public optimizeForTinyScreen = () => {
        this.widget.body.classList.toggle("optimizedForTinyScreen", false);
        setTimeout(() => {
            const messageContainerRect = this.widget.getElementById("message-container").getBoundingClientRect();
            if ((this.widget.documentElement.clientHeight / 1.5) > messageContainerRect.height) {
                this.widget.body.classList.toggle("optimizedForTinyScreen", true);
            }
        }, 10)
    }

    public addStylesheet = (url: string, el: Document, callback?: Function, failure?: Function) => {
        const link = document.createElement('link');
        if (callback) {
            link.addEventListener('load', () => callback(), { once: true });
            link.addEventListener('error', () => failure(), { once: true });
        }
        el.head.appendChild(Object.assign(link ,{href: url, rel:"stylesheet"}));
    }

    public addStylesnode = (content: string, el: Document) => {
        if (!this.config.custom_css) {
            const style = el.createElement("style");
            style.innerHTML = content;
            el.head.appendChild(style);
        }
    }

    public addStyle = (el: HTMLElement, prop: string, value: string) => {
        if (!this.config.custom_css) {
            el.style.setProperty(prop, value);
        }
    }

    public isOutOfBound(frame: DOMRect) {
        return (frame.top < 0) || (frame.left < 0) || ((window.innerHeight - frame.bottom) < 0) || ((window.innerWidth - frame.right) < 0);
    }
}
